/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-10",
    versionChannel: "nightly",
    buildDate: "2024-06-10T00:06:01.095Z",
    commitHash: "3b9bb3f5dbc118a3de6711560502517db18c87e7",
};
